<template>
  <div>
    <nav-bar>
      <div class="container flex">
        <div class="Classification relative">
          <div class="Classification-content">
            <div
              class="ClassificationItem flex align-center"
              v-for="(item, index) in classifyList"
              :key="index"
              :class="active === index ? 'active' : ''"
              @click="handleGetGoodsList(item, index)"
            >
              <div class="img" v-if="item.iconUrl">
                <img :src="item.iconUrl" alt="" v-if="index === 0" />
                <img :src="item.iconUrl | filterPicture" alt="" v-else />
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="shopContainer">
          <div class="secondaryClassification flex align-center">
            <div
              class="S-C-Item flex align-center flex-center"
              v-for="(item, index) in shopType"
              :key="index"
              @click="OnClickTabs(item, index)"
              :class="{ active: tabIndex == index }"
            >
              <p>{{ item.label }}</p>
            </div>
          </div>
          <div class="shopContent">
            <loadingVue v-if="loadingVue" class="mt100" />
            <div v-else>
              <div class="" v-if="goodsList.length">
                <product-card
                  v-for="(item, index) in goodsList"
                  :key="index"
                  :goods="item"
                  @toDetail="toDetail(item.id)"
                  @getGoodsId="handelCart"
                  @handel-collect="handleCollect(item,index)"
                  class="content"
                ></product-card>
                <div class="goodsLoading" v-if="goodsLoading">
                  Espera un momento……
                </div>
              </div>
              <template v-else>
                <div class="empty-container">
                  <img src="@/assets/public/empty.png" alt="" />
                  <span>Nada todavía~</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        :visible.sync="dialogVisible"
        width="1400px"
        :modal-append-to-body="false"
        @close="hiddenDialog"
      >
        <goods-carts v-if="cartsVisible" :goodsId="goodsId"></goods-carts>
      </el-dialog>
    </nav-bar>
  </div>
</template>

<script>// @ts-nocheck

import loadingVue from '../../components/loading.vue'
import stickyVue from '../../components/sticky.vue'
import navBar from '@/components/navBar/index.vue'
import productCard from '@/components/product/index.vue'

import goodsCarts from '@/views/goods/index.vue'

import { mapState } from 'vuex'

export default {
  components: {
    loadingVue,
    stickyVue,
    navBar,
    productCard,
    goodsCarts
  },

  data() {
    return {
      tabIndex: null,
      active: 0,
      classifyList: [],
      goodsList: [],
      downIf: true,
      query: {
        pageNo: 1,
        pagesize: 10,
        categoryId: '',
        memberId: '',
        type: ''
      },
      activeWatch: 0,
      goodsLoading: '',
      classListVisible: false,
      loading: true,
      load: true,
      goodsTotal: 0,
      forbidScroll: true,
      dialogVisible: false,
      goodsId: '',
      cartsVisible: false,
      shopType: [
        {
          label: 'Nuevo producto',
          value: 0
        },
        {
          label: 'Descuento',
          value: 1
        },
        {
          label: 'Económico',
          value: 2
        },
        {
          label: 'Calidad',
          value: 3
        },
        {
          label: 'Premium',
          value: 4
        }
      ],
      isFixed: false,
      offsetTop: null,
      firstLoading: true,
      loadingVue: true
    }
  },

  computed: {
    ...mapState({
      info: (state) => state.app.info
    })
  },

  watch: {
    '$store.state.app.scrollTop'(val) {
      if (this.$route.path !== '/') return

      const clientHeight = document.documentElement.clientHeight
      // 在页面未加载商品列表数据前，禁止通过滚动加载数据
      if (this.forbidScroll) return
      if (
        document.body.scrollHeight - (val + clientHeight) < 1000 &&
        this.loading &&
        this.load
      ) {
        console.log('触发加载')
        ++this.query.pageNo
        this.getGoodsList()
      }
      //  console.log(document.body.scrollHeight - (val + clientHeight))
    },
    $route() {
      const route = this.$route
      if (route.path !== '/') {
        window.removeEventListener('scroll', this.initHeight)
      }
    }
  },

  created() {
    this.getClassList()
  },
  mounted() {
    window.addEventListener('scroll', this.initHeight)
    this.$nextTick(() => {
      //获取对象相对于版面或由 offsetTop 属性指定的父坐标的计算顶端位置
      // this.offsetTop = document.querySelector('#boxFixed').offsetTop;
    })
  },
  methods: {
    initHeight() {
      // 设置或获取位于对象最顶端和窗口中可见内容的最顶端之间的距离 (被卷曲的高度)
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
      this.isFixed = scrollTop > this.offsetTop ? true : false
    },
    OnClickTabs(val) {
      this.tabIndex = val.value
      console.log('val', val)
      this.query.pageNo = 1
      this.query.type = val.value + 1
      this.goodsList = []
      this.loadingVue = true
      this.handleGetGoods()
    },

    toDetail(goodsId) {
      this.$router.push({
        path: '/goods',
        name: 'goods',
        params: { id: goodsId }
      })
    },

    getClassList() {
      this.$axios
        .post('/goodsCategory/auth/list', {})
        .then((res) => {
          console.log('分类列表', res)
          this.classifyList = res.data.result.rows
          this.classifyList.unshift({
            name: 'Todos',
            iconUrl: require('../../assets/home/todos.png')
          })
          // this.classifyList.unshift({name: 'Compras frecuentes'})
          console.log('商品分类', this.classifyList)

          setTimeout(() => {
            this.classListVisible = true
          }, 300)

          this.getGoodsList()

          //分类列表固定一行6个类，小于3行则不显示下拉箭头
          if (this.classifyList.length <= 12) {
            this.downIf = false
          }
        })
        .catch(() => {
          this.classListVisible = true
        })
    },
    handleGetGoodsList(item, index) {
      console.log('val', item, index)
      this.loadingVue = true
      window.scrollTo(0, 0)
      this.getGoodsList(item, index)
    },
    getGoodsList(item, index) {
      console.log('query', item, index)
      this.loading = false
      this.goodsLoading = true
      if (index || index === 0) this.active = index
      // 判断是否切换了分类，本想用watch来监听，但监听响应太慢，起不到效果
      if (this.activeWatch !== this.active) {
        // 切换了分类就从第0页开始请求
        this.query.pageNo = 1
        this.query.type = ''
        this.goodsList = []
        this.tabIndex = null
        console.log('切换了分类')
      }
      ;(this.activeWatch = this.active && this.query.pageNo === 1) &&
        (this.goodsList = [])
      // 保存本次active，用来下回切换时做判断
      this.activeWatch = this.active
      if (this.active === 0) {
        this.query.categoryId = ''
      } else {
        // 获取分类商品列表需传的参数
        this.query.categoryId = this.classifyList[this.active].id
        this.query.memberId = ''
      }
      this.handleGetGoods()
    },
    handleGetGoods() {
      this.$axios.post('/goods/auth/list', this.query).then((res) => {
        this.loading = true
        console.log('商品列表----', res)
        if (this.active === 0) {
          console.log('获取全部', this.query, res)
        }

        let result = res.data.result.rows
        result.map((item) => {
          this.goodsList.push(item)
          this.goodsList = [...new Set(this.goodsList)]
        })
        this.goodsTotal = res.data.result.total
        this.load = this.goodsTotal > this.goodsList.length
        this.goodsLoading = false
        this.loadingVue = false
        this.forbidScroll = false
      })
    },

    handelCart($event) {
      this.goodsId = $event
      this.cartsVisible = true
      this.dialogVisible = true

      console.log(this.goodsId)
    },

    hiddenDialog() {
      this.dialogVisible = false
      this.cartsVisible = false
    },
    handleCollect(e,i) {
      let collectTemp = {
        objectType: 'GOODS',
        type: 'COLLECT',
        objectId: e.id,
        whether: e.collected ? false : true
      }
      this.$axios.post('/operation/operation', collectTemp).then((res) => {
        const { data } = res
        console.log('res',res)
        if (data.result) {
          this.goodsList.forEach((item,index)=>{
            if (index === i){
              item.collected = item.collected ? false:true
            }
          })
          this.$message.success('Éxito de operación')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1220px;
  min-height: 500px;
  margin: auto;
  margin-top: 20px;
  .Classification {
    // width: 200px;
    height: auto;
    margin-right: 40px;

    .Classification-content {
      position: sticky;
      top: 140px;

// height: 500px;
      overflow: hidden;
      overflow-y: auto;
      padding: 10px;
      background: #efefef;
      border-radius: 6px;
    }
    .ClassificationItem {
      padding: 4px;
      margin-bottom: 8px;
      cursor: pointer;
      p {
        padding: 0 16px;
      }
      .img {
        width: 54px;
        height: 54px;

        img {
          width: 54px;
          height: 54px;
          border-radius: 10px;

          object-fit: cover;
        }
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
    }
    .active {
      overflow: hidden;
      color: #45b035;
      white-space: normal;
      background: #f7fff7;
      border: 1px solid #8acf66;
      border-radius: 20px;
    }
  }
  .shopContainer {
    width: 100%;
    min-height: 500px;
    .secondaryClassification {
      height: 40px;
      .active {
        color: #45b035;
        border: 1px solid #8acf66;
      }
      .S-C-Item {
        padding: 8px 25px;
        margin-right: 16px;
        background: #e2e6eb;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12);
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
  .shopContent {
    margin-top: 16px;

    .content {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .goodsLoading {
      margin-top: 50px;
      color: #409eff;
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 0;
}
</style>
